import React from "react";
import { injectIntl } from "react-intl";
import {actions} from "../../../app/store/ducks/auth.duck";
import {useDispatch} from "react-redux";
import SearchDropdown from "../../../app/partials/layout/SearchDropdown";
import UserNotifications from "../../../app/partials/layout/UserNotifications";
import MyCart from "../../../app/partials/layout/MyCart";
import QuickActionsPanel from "../../../app/partials/layout/QuickActionsPanel";
import QuickPanelToggler from "./QuickPanelToggle";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../utils/utils";
import { Link } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from "@material-ui/core";
import LogoutIcon from '@material-ui/icons/ExitToApp';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
function TopBar(props) {
  
  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);
  const dispatch = useDispatch();
  return (
    <div className="kt-header__topbar">
      <UserProfile showAvatar={true} showHi={true} showBadge={false} />
            <OverlayTrigger delay={{hide: 200, show: 100}}
                            overlay={(props) => (<Tooltip  {...props}>Ana Sayfa</Tooltip>)}
                            placement="bottom">
                <Link to=""
                      style={{display: 'flex', alignSelf: 'center', marginLeft: 16, marginRight: 16, color: '#65646a'}}>
                    <HomeIcon fontSize="large"/>
                </Link>
            </OverlayTrigger>

            <OverlayTrigger delay={{hide: 200, show: 100}}
                            overlay={(props) => (<Tooltip  {...props}>Çıkış</Tooltip>)}
                            placement="bottom">
                <Link onClick={() => setOpenLogoutModal(true)}
                      style={{display: 'flex', alignSelf: 'center', marginRight: 16, color: '#b8251a'}}>
                    <LogoutIcon fontSize="large"/>
                </Link>
            </OverlayTrigger>
      <Dialog
        open={openLogoutModal}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        style={{ textAlign: "left" }}
      >
        <DialogTitle>
          {props.intl.formatMessage({id: "AUTH.LOGOUT_TITLE"})}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {props.intl.formatMessage({id: "AUTH.LOGOUT_TEXT"})}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{padding:16}}>
          <Button
            color="primary"
            onClick={()=>setOpenLogoutModal(false)}
          >
            {props.intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </Button>
          <Button
            variant="contained" color="primary"
            onClick={()=>{
              setOpenLogoutModal(false);
              dispatch(actions.logout());
            }}
          >
            {props.intl.formatMessage({ id: "AUTH.LOGOUT_BUTTON" })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default injectIntl(
  (TopBar)
);